article,body,div,figure,form,h1,h2,h3,h4,html,hr,img,iframe,label,li,nav,p,span,strong,ul{font-size:100%;vertical-align:baseline;margin:0;padding:0;outline:0;border:0;background:0 0;   list-style-type: none;}html{overflow-y:scroll;overflow-x:hidden}body{overflow: hidden;}
button, input, textarea {
  margin: 0;
  padding: 0;
  background: none;
  border: 0;
//  outline:0;
  -webkit-appearance: none;
  border-radius: 0;
  color: var(--the-color)
}
html {
  /*font-size: 10px;*/
  -webkit-tap-highlight-color: rgba(0,0,0,0);
}
*, *:before, *:after {
   -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
  -moz-box-sizing: border-box;    /* Firefox, other Gecko */
  box-sizing: border-box;         /* Opera/IE 8+ */
}
button {
  cursor: pointer;
}