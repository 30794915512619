@import "../../global-styles/_cssVariables.scss" ;
.h1 {
    font-size: 15vw;
    text-transform: uppercase;
    text-align: center;
    padding-top: 4rem; 
    margin-top: 0;
  @media(min-width: $tablet-break) {
      font-size: 82px;
  }
}
.h1Word {
    background-size: cover;
    @supports (background-clip: text) and (-webkit-text-stroke: 2px black){
        background-clip: text;
        color: transparent;
        -webkit-text-stroke: 1px #333; 
    }
}
.explanatoryText {
    text-align: center;
    margin-bottom: 1.5em;
    @media(min-width: $tablet-break) {
        font-size: 1.25em; 
    }
}