.footer {
    padding: var(--gutter);
    display: flex;
    align-items: center;
    position:absolute;
    left: 0;
    bottom: 0;
}

.poweredText {
    text-transform: uppercase;
    font-size: 10px; 
    margin-right: 8px; 
}
.logo {
    height: 20px; 
}