@import "../../global-styles/cssVariables";

.choiceContainer {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    @media(min-width: $tablet-break) {
        flex-wrap: nowrap;
    }
}
.choice {
    width: calc( 50% - (var(--gutter) / 2));
    position:relative; 
    overflow:hidden;
    border-radius: 3px;
    margin-bottom: var(--gutter);
    @media(min-width:$tablet-break) {
        width: auto;
        flex: 1;
        &:not(:first-child) {
            margin-left: var(--gutter);
        }
    }
    &:after {
        display:block;
        content:'';
        position:absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        border-radius: 3px; 
        border: 1px solid #333;
        pointer-events: none;
    }
    &.selected {
        background:#333;
        color:white;
        &:after {
            border-width: 3px; 
        }
    }
    span {
        display:block;
    }
}
.imgContainer {
    position:relative;
    width: 100%;
    padding-top: 100%;
}
.img {
    position:absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center center;
}
.choiceTitle {
    padding: calc(var(--gutter) / 2)
}
.buttonContainer {
    text-align: center;
    margin-top: var(--gutter);
}